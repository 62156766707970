import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'ekipa-registration-success',
  templateUrl: './registration-success.component.html',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
})
export class RegistrationSuccessComponent {



}
