import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { countries, foundingYears, industries } from '@ekipa/shared';
import { TranslocoModule } from '@ngneat/transloco';
import { ChallengesService } from '../../../../services/challenges.service';
import { ContinueButtonComponent } from '../continue-button/continue-button.component';
import { RegistrationStepInputComponent } from '../registration-step.component';

@Component({
  selector: 'ekipa-startup-input',
  templateUrl: './startup-input.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    TranslocoModule,
    ContinueButtonComponent
  ]
})
export class StartupInputComponent extends RegistrationStepInputComponent implements OnInit {
  industriesList = industries;
  foundingYears = foundingYears;
  countriesList = countries;
  challengesService = inject(ChallengesService);

  constructor() {
    super();

  }

  ngOnInit(): void {
    if (this.challengesService.isFast50) {
      this.form.get('country').disable();
      this.form.get('country').setValue('Germany');
      this.industryCtrl.clearValidators();
    }

  }


  get industryCtrl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('industry');
  }


  get foundedInCtrl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('foundedIn');
  }

}
