import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ekipa-personal-profile',
  template: '',
})
export class RegistrationStepInputComponent {
  @Output() nextStep = new EventEmitter();
  @Output() back = new EventEmitter();
  @Input() form: UntypedFormGroup;

}
