<button (click)="dialogRef.close()" class="absolute top-0 right-0 m-8 transform">
  <ekipa-close-icon class="text-white"></ekipa-close-icon>
</button>
<div class="mx-auto sm:block md:flex justify-center sm:w-[80vw] max-w-4xl min-h-96">
  <ng-container *ngIf="data.dialogState === AuthDialogState.Login">
    <div class="sm:w-full md:w-3/5 xl:flex xl:justify-center lg:max-h-35 bg-white">
      <div class="p-8 mx-auto md:px-0 md:w-4/5 flex flex-col h-full justify-center">
        <div class="mt-8 md:mt-4 mb-4 text-lg md:text-2xl font-normal md:font-medium">
          {{ 'loginPageBox.login' | transloco }}
        </div>
        <ekipa-login></ekipa-login>
      </div>
    </div>
    <div *ngIf="!challengesService.isFast50"
      class="flex flex-col justify-center p-8 bg-center bg-no-repeat bg-cover sm:w-full md:w-fit md:pl-10 items-left bg-auth">
      <div class="">
        <h3 class="mb-6 text-lg text-white">
          Start innovating <br> together
        </h3>
      </div>
      <div class="mt-34 mb-4 tracking-wider text-white">
        {{
        data.dialogState === AuthDialogState.Login
        ? ('auth.dontHaveAccount' | transloco)
        : ('auth.haveAccount' | transloco)
        }}
      </div>

      <div class="">
        <button
          class="px-12 py-2 text-white border border-white rounded-full cursor-pointer hover:bg-white hover:bg-opacity-25 focus:outline-none"
          (click)="
            data.dialogState === AuthDialogState.Login
              ? (data.dialogState = AuthDialogState.Register)
              : (data.dialogState = AuthDialogState.Login)
          " type="button">
          {{
          data.dialogState === AuthDialogState.Login
          ? ('auth.register' | transloco)
          : ('auth.login' | transloco)
          }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="data.dialogState === AuthDialogState.Register">
    <div class="p-8 h-auto sm:w-full w-auto">
      <ekipa-register [registrationState]="data.registrationState"></ekipa-register>
    </div>
  </ng-container>
</div>
