<div class="">
  <span class="text-xl">You are taking part &nbsp;</span>
  <span class="text-xl text-primary">as:
  </span>
</div>
<div class="w-full mx-auto flex flex-col md:flex-row gap-4 items-stretch justify-center my-4">
  <div class="border hover:border-primary rounded-2xl p-5 w-full my-4 md:my-2 shadow-md mb-12 cursor-pointer"
    (click)="form.get('profileType').setValue('personal'); nextStep.next(true)">
    <div class="flex flex-col justify-between items-center text-gray-700">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"
        stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
      </svg>
      <span class="text-base font-bold ">Innovator</span>
      <div class="p-leading-4 text-center">
        <span class="text-sm font-normal text-gray-500">As an ambitious professional, researcher or student, you are
          interested in participating
          in one of our challenges to solve present-day issues either alone or with others.</span>
      </div>
    </div>
  </div>
  <div class="border hover:border-primary rounded-2xl p-5 w-full my-4 md:my-2 shadow-md mb-12 cursor-pointer"
    (click)="form.get('profileType').setValue('startup'); nextStep.next(true)">
    <div class="flex flex-col justify-between items-center text-gray-700">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 mb-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"
        stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
      </svg>
      <span class="text-base font-bold">Startup & Company</span>
      <div class="p-leading-4 text-center">
        <span class="text-sm font-normal text-gray-500">You are representing a company with a promising or even
          validated solution, looking for potential collaboration with established enterprises.</span>
      </div>
    </div>
  </div>
</div>
