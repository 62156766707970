import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { countries } from '@ekipa/shared';
import { TranslocoModule } from '@ngneat/transloco';
import { ContinueButtonComponent } from '../continue-button/continue-button.component';
import { RegistrationStepInputComponent } from '../registration-step.component';

@Component({
  selector: 'ekipa-credential-input',
  templateUrl: './credential-input.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatCheckboxModule,
    ContinueButtonComponent,
    TranslocoModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class CredentialInputComponent extends RegistrationStepInputComponent {
  countriesList = countries;
  public isPasswordHidden = true;
  @ViewChild('password', { static: true }) passwordInput: ElementRef;
  @Input() isStartup = false


  constructor(private fb: UntypedFormBuilder,
    private renderer: Renderer2
  ) {
    super()
  }

  get country(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('country');
  }

  get password(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('password');
  }

  public togglePasswordVisibility(): void {
    this.isPasswordHidden = !this.isPasswordHidden;
    this.renderer.setAttribute(
      this.passwordInput.nativeElement,
      'type',
      this.isPasswordHidden ? 'password' : 'text'
    );
  }

  get displayNameCtrl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('displayName');
  }

}
