import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { LoginInputComponent } from './login-input/login-input.component';


@Component({
  selector: 'ekipa-login',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LoginInputComponent
  ],
  templateUrl: './login.component.html',
})
export class LoginComponent {
  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) { }
  @Output() signedIn = new EventEmitter<boolean>();

  signIn($event: any): void {
    this.authService.signIn(
      $event.email,
      $event.password
    ).then(
      () => {
        this.dialog.closeAll()
        this.signedIn.emit(true);
      }
    )
  }

}
