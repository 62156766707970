import { Injectable } from '@angular/core';
import { SignUpRequest } from '@ekipa/shared';

const SAVED_DATA_KEY = 'ekipaUserRegistrationState'
@Injectable()
export class UserRegistrationSessionStorageService {


  _data: SignUpRequest;
  get data() {
    return this._data;
  }

  set data(value: SignUpRequest) {
    delete value.password;
    sessionStorage.setItem(SAVED_DATA_KEY, JSON.stringify(value));
    this._data = value;
  }

  clearSessionStorage = () => sessionStorage.removeItem(SAVED_DATA_KEY);

  constructor() {
    this._data = JSON.parse(sessionStorage.getItem(SAVED_DATA_KEY)) || {};
  }


}
