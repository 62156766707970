import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { ContinueButtonComponent } from '../continue-button/continue-button.component';
import { RegistrationStepInputComponent } from '../registration-step.component';


@Component({
  selector: 'ekipa-how-did-you-hear-from-us',
  standalone: true,
  imports: [CommonModule, MatRadioModule, FormsModule, ContinueButtonComponent, ReactiveFormsModule],
  templateUrl: './how-did-you-hear-from-us.component.html',
})
export class HowDidYouHearFromUsComponent extends RegistrationStepInputComponent {
  public options = [
    'LinkedIn',
    'Other social network (Facebook, Twitter, Instagram, etc.)',
    'Friend / Collegue',
    'Search engine (Google, Bing, etc.)',
    'University',
    'Email',
    'Other',
  ];
  constructor() {
    super()
  }
}
