import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SignUpRequest } from '@ekipa/shared';
import { API_URL } from '../tokens/tokens';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  constructor(private http: HttpClient, @Inject(API_URL) private apiURL: string) { }

  signUp(request: SignUpRequest) {
    return this.http.post<any>(`${this.apiURL}account/create`, request)
  }
}
