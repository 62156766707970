import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { filter, take, tap } from 'rxjs/operators';
import { ChallengesService } from '../../../services/challenges.service';
import { IconsModule } from '../../icons/icons.module';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent, RegistrationState } from '../register/register.component';


export enum AuthDialogState {
  Login,
  Register
}

@Component({
  selector: 'ekipa-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    RegisterComponent,
    LoginComponent,
    IconsModule
  ]
})
export class AuthDialogComponent implements OnInit {
  AuthDialogState = AuthDialogState;

  challengesService = inject(ChallengesService);

  constructor(
    public dialogRef: MatDialogRef<AuthDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dialogState: AuthDialogState, registrationState?: RegistrationState },
    private dialog: MatDialog,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationStart),
      tap(() => this.dialog.closeAll()),
      take(1)
    ).subscribe();

  }

}
