import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, TransferState, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { ALL_CHALLENGES_OVERVIEW, CHALLENGES_OVERVIEW, Challenge, GET_CHALLENGE_BY_ID, GET_ONGOING_CHALLENGES } from '@ekipa/shared';
import { Apollo } from 'apollo-angular';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, EMPTY, Observable, catchError, map, shareReplay, switchMap, tap } from 'rxjs';

export interface ChallengeConfig {
  id: string;
  header?: 'deloitte',
  logoPosition: 'behindHeader' | 'left' | 'right';
  documentClass?: string;
}

const challengeConfigs: ChallengeConfig[] = [
  {
    id: 'deloitte-quantum-24',
    logoPosition: 'left',
    header: 'deloitte',
    documentClass: 'deloitte'
  },
  {
    id: 'deloitte-fast50-2023',
    logoPosition: 'left',
    header: 'deloitte',
    documentClass: 'deloitte'
  }
]


@Injectable({
  providedIn: 'root'
})
export class ChallengesService {

  readonly currentChallenge$: Observable<Challenge>;
  lang: 'de-de' | 'en-us';
  challengeId$: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  public isBrowser: boolean;
  isFast50 = false;
  hasConfig = false;
  config: ChallengeConfig;
  route = inject(ActivatedRoute);
  document = inject(DOCUMENT);

  constructor(
    private apollo: Apollo,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) platformId: string,
  ) {

    this.challengeId$.asObservable().pipe(tap((challengeId) => {
      this.loadConfig(challengeId);
    })).subscribe();

    this.currentChallenge$ = this.challengeId$.pipe(
      shareReplay(1),
      switchMap((challengeId) => {
        return this.getChallengeById(challengeId);
      })
    )
    this.isBrowser = isPlatformBrowser(platformId);

  }

  loadConfig(challengeId: string) {
    const config = challengeConfigs.find(config => config.id === challengeId);
    if (!config) return;
    this.document.body.classList.value = '';
    if (config.documentClass) this.document.body.classList.add(config.documentClass);
    if (!config) { this.config = null; return; };
    this.config = config;
  }


  getChallengeById(challengeUID: string): Observable<Challenge> {
    return this.apollo.query({
      query: GET_CHALLENGE_BY_ID,
      context: { clientName: 'prismic' },
      variables: { lang: 'en-us', uid: challengeUID }
    }).pipe(shareReplay(1), map(({ data, error }) => {
      if (data && (data as any).allChallenges.edges.length) {
        if (!this.isBrowser) {
          this.transferState.toJson();
        }
        const challenge: Challenge = plainToClass(Challenge, (data as any).allChallenges.edges[0].node);
        return challenge;
      }
    }))

  }

  getChallengesByMultipleIds(ids: string[]): Observable<ApolloQueryResult<any>> {
    if (ids?.length === 0) {
      return EMPTY;
    } else {
      return this.apollo.query({
        query: GET_CHALLENGE_BY_ID,
        context: { clientName: 'prismic' },
        variables: { uid_in: ids, lang: 'en-us' }
      });
    }
  }

  loadChallengesOverview(isCompleted?: boolean, isListed = true): Observable<Challenge[]> {
    return this.apollo.query({
      query: CHALLENGES_OVERVIEW,
      context: { clientName: 'prismic' },
      variables: { lang: 'en-us', is_completed: isCompleted, is_listed: isListed }
    }).pipe(
      map(
        ({ data, error }) => {
          if (data) {
            const challenges: Challenge[] = (data as any).allChallenges.edges.map(challenge => plainToClass(Challenge, challenge.node));
            if (!this.isBrowser) {
              this.transferState.toJson();
            }
            return challenges;
          }
        }
      ), catchError((err) => {
        console.log('err', err)
        return EMPTY;
      })
    );
  }


  loadAllChallengesOverview(isListed = true): Observable<Challenge[]> {
    return this.apollo.query({
      query: ALL_CHALLENGES_OVERVIEW,
      context: { clientName: 'prismic' },
      variables: { lang: 'en-us', is_listed: isListed }
    }).pipe(
      map(
        ({ data, error }) => {
          if (data) {
            const challenges: Challenge[] = (data as any).allChallenges.edges.map(challenge => plainToClass(Challenge, challenge.node));
            if (!this.isBrowser) {
              this.transferState.toJson();
            }
            return challenges;
          }
        }
      ), catchError((err) => {
        console.log('err', err)
        return EMPTY;
      })
    );
  }

  getOngoingChallenges() {
    return this.apollo.query({
      query: GET_ONGOING_CHALLENGES,
      context: { clientName: 'prismic' },
    }).pipe(
      map(
        ({ data, error }) => {
          if (data) {
            const challengeSlices: any[] = (data as any).allOngoing_challenges_pages.edges[0].node;
            return challengeSlices;
          }
        }

      )
    );
  }
}


