<form [formGroup]="form" class="min-h-60">
  <!-- country -->
  <mat-form-field class="w-full">
    <mat-label>Where are you based?</mat-label>
    <mat-select formControlName="country" required>
      <mat-option *ngFor="let country of countriesList" [value]="country" class="h-6">
        <span>{{ country }}</span>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('country').hasError('required')"> Location is required to determine your eligibility for
      certain challenge.</mat-error>
  </mat-form-field>

  <!-- birth year -->
  <mat-form-field class="w-full mb-2">
    <mat-label>Birth year</mat-label>
    <mat-select formControlName="birthYear">
      <mat-option *ngFor="let birthYear of birthYearList" [value]="birthYear" class="h-6">
        <span>{{ birthYear }}</span>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('birthYear').hasError('required')">Your location is required to determine
      eligability of your participation. </mat-error>
  </mat-form-field>

  <!-- innovator type -->
  <mat-form-field class="w-full mb-2">
    <mat-label>What type of innovator are you ?</mat-label>
    <mat-select formControlName="innovatorType">
      <mat-option *ngFor="let innovatorType of innovatorTypes" [value]="innovatorType" class="h-6">
        <span>{{ innovatorType| titlecase}}</span>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('innovatorType').hasError('required')">Innovator type is required.</mat-error>
  </mat-form-field>


  <!-- skills -->
  <mat-form-field class="w-full">
    <mat-label>{{ "profile.skills" | transloco }}</mat-label>
    <mat-chip-grid #skillchipgrid>
      <mat-chip-row *ngFor="let skill of skills" (removed)="removeSkill(skill)">
        {{ skill }}
        <mat-icon svgIcon="cancel" matChipRemove></mat-icon>
      </mat-chip-row>
    </mat-chip-grid>
    <input matInput #skillInput placeholder="{{'editProfileView.skillBoxText' | transloco}}"
      (blur)="addSkillOnBlur($event)" [formControl]="skillInputCtrl" [matAutocomplete]="autoSkills"
      [matChipInputFor]="skillchipgrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addSkill($event)" />
    <mat-autocomplete #autoSkills="matAutocomplete" (optionSelected)="selectedSkill($event)">
      <mat-option *ngFor="let skill of filteredSkills$ | async" [value]="skill">
        {{ skill }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <!--   university     -->
  <mat-form-field class=" w-full">
    <mat-label>{{ "profile.university" | transloco }}</mat-label>
    <input matInput placeholder="{{'editProfileView.universityBox' | transloco}}" [formControl]="universityCtrl" />
    <mat-error *ngIf="universityCtrl.touched && !universityCtrl.valid">
      {{ "createTeam.universityError" | transloco }}
    </mat-error>
  </mat-form-field>

  <!-- faculties -->
  <mat-form-field class=" w-full my-2">
    <mat-label>{{ "profile.faculties" | transloco }}</mat-label>
    <mat-chip-grid #facultyChipGrid>
      <mat-chip-row *ngFor="let faculty of faculties" (removed)="removeFaculty(faculty)">
        {{ faculty }}
        <mat-icon svgIcon="cancel" matChipRemove></mat-icon>
      </mat-chip-row>
    </mat-chip-grid>
    <input #facultyInput matInput placeholder="{{'editProfileView.facultyBox' | transloco}}"
      (blur)="addFacultyOnBlur($event)" [formControl]="facultyInputCtrl" [matAutocomplete]="autoFaculty"
      [matChipInputFor]="facultyChipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addFaculty($event)" />
    <mat-autocomplete #autoFaculty="matAutocomplete" (optionSelected)="selectedFaculty($event)">
      <mat-option *ngFor="let faculty of filteredFaculties$ | async" [value]="faculty">
        {{ faculty }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
<div class="flex justify-between">
  <button class="ml-2 btn btn-background" (click)="back.next(true)">
    Back
  </button>
  <ekipa-continue-button [form]="form" (validClick)="nextStep.next(true)">
  </ekipa-continue-button>
</div>
