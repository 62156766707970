import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SignUpRequest } from '@ekipa/shared';
import { take } from 'rxjs/internal/operators/take';
import { ChallengesService } from '../../../services/challenges.service';
import { SignUpService } from '../../../services/sign-up.service';
import { CredentialInputComponent } from './credential-input/credential-input.component';
import { HowDidYouHearFromUsComponent } from './how-did-you-hear-from-us/how-did-you-hear-from-us.component';
import { IndividualExpertiseComponent } from './individual-expertise/individual-expertise.component';
import { ProfileTypeSelectionComponent } from './profile-type-selection/profile-type-selection.component';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { StartupInputComponent } from './startup-info/startup-input.component';
import { UserRegistrationSessionStorageService } from './user-registration.service';

export enum RegistrationState {
  ProfileTypeSelection,
  IndividualExpertise,
  CompanyInformation,
  CredentialsInput,
  HowDidYouHearFromUs,
  RegisterSuccess,
}

@Component({
  selector: 'ekipa-register',
  standalone: true,
  imports: [
    CommonModule,
    IndividualExpertiseComponent,
    StartupInputComponent,
    CredentialInputComponent,
    HowDidYouHearFromUsComponent,
    ProfileTypeSelectionComponent,
    RegistrationSuccessComponent,
    FormsModule,
    MatDialogModule
  ],
  providers: [UserRegistrationSessionStorageService],
  templateUrl: './register.component.html'
})

export class RegisterComponent implements OnInit, OnDestroy {
  @Input() registrationState?: RegistrationState;
  RegistrationState = RegistrationState;
  formBuilder = new FormBuilder();
  state: RegistrationState = RegistrationState.ProfileTypeSelection;
  profileTypeFormGroup: FormGroup;
  expertiseFormGroup: FormGroup;
  startupInfoFormGroup: FormGroup;
  credentialFormGroup: FormGroup;
  howDidYouHearFromUsFormGroup: FormGroup;
  isLoading = false;
  dialog = inject(MatDialog);

  constructor(
    public challengesService: ChallengesService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private signUpService: SignUpService,
    private snackBar: MatSnackBar,
    private userRegistrationService: UserRegistrationSessionStorageService
  ) {
  }

  ngOnInit(): void {
    if (this.registrationState) {
      this.state = this.registrationState;
    }
    const savedData: SignUpRequest = this.userRegistrationService.data;
    this.profileTypeFormGroup = this.formBuilder.group({
      profileType: [savedData?.profileType || 'personal']
    })
    this.expertiseFormGroup = this.formBuilder.group({
      innovatorType: [savedData.data?.innovator_type, [Validators.required]],
      university: [savedData.data?.university || ''],
      faculties: [savedData.data?.faculties || []],
      skills: [savedData.data?.skills || []],
      country: [savedData.data?.country, Validators.required],
      birthYear: [savedData.data?.birth_year, Validators.required],
    });
    this.startupInfoFormGroup = this.formBuilder.group({
      industry: [savedData.data?.industry || '', Validators.required],
      country: [savedData.data?.country || '', Validators.required],
      foundedIn: [savedData.data?.founded_in || null],
      displayName: [savedData?.displayName || '', [Validators.required]],
    });

    this.credentialFormGroup = this.formBuilder.group({
      password: [savedData.password || '', Validators.compose([Validators.required, Validators.minLength(7)])],
      email: [savedData.email || '', Validators.compose([
        Validators.required,
        Validators.email
      ])],
      termsAccepted: [false, Validators.requiredTrue],
      displayName: [savedData.displayName || '', [Validators.required]],
    });

    this.howDidYouHearFromUsFormGroup = this.formBuilder.group({
      howDidYouHearFromUs: ['']
    });
  }

  ngOnDestroy(): void {
    this.userRegistrationService.data = this.data;
  }

  onSignup(): void {
    this.isLoading = true;
    this.setCurrentChallengeCookie(); // TODO use sessionStorage to move user to challenge after registration
    this.userRegistrationService.clearSessionStorage();

    this.signUpService.signUp(this.data).subscribe({

      complete: () => {
        this.state = RegistrationState.RegisterSuccess;
        this.cdRef.markForCheck();
        this.isLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.snackBar.open(
          error.error.error,
          null,
          { duration: 2500 }
        )
        this.isLoading = false;
      }
    })
  }

  get data(): SignUpRequest {
    const howDidYouHearFromUs = {
      how_did_you_hear_from_us: this.howDidYouHearFromUsFormGroup.value.howDidYouHearFromUs
    };
    return {
      profileType: this.challengesService.isFast50 ? 'startup' : this.profileTypeFormGroup.value.profileType,
      displayName: this.isStartup ? this.startupInfoFormGroup.value.displayName : this.credentialFormGroup.value.displayName,
      email: this.credentialFormGroup.value.email.toLowerCase(),
      data: this.isStartup ? {
        industry: this.startupInfoFormGroup.value.industry,
        representant_name: this.credentialFormGroup.value.displayName,
        country: this.startupInfoFormGroup.value.country,
        founded_in: this.startupInfoFormGroup.value.foundedIn,
        ...howDidYouHearFromUs
      } : {
        university: this.expertiseFormGroup.value.university,
        skills: this.expertiseFormGroup.value.skills,
        innovator_type: this.expertiseFormGroup.value.innovatorType,
        country: this.expertiseFormGroup.value.country,
        faculties: this.expertiseFormGroup.value.faculties,
        birth_year: this.expertiseFormGroup.value.birthYear,
        ...howDidYouHearFromUs
      },
      password: this.credentialFormGroup.value.password,
    }

  }

  get isStartup(): boolean {
    if (this.challengesService.isFast50) return true;
    return this.profileTypeFormGroup.value.profileType === 'startup';
  }

  private setCurrentChallengeCookie(): void {
    this.challengesService.currentChallenge$.pipe(take(1)).subscribe((challenge) => {
      // if there is current challenge & current route includes challengeID
      // user might have navigated to another page but still have the currentChallenge flagged (TODO)
      if (challenge && this.router.url.includes(challenge.uid)) {
        localStorage.setItem('currentChallengeID', challenge.uid);
      }
    })
  }

}
