<form [formGroup]="form" class="min-h-60 mb-6">
  <label>One last thing, how did you hear from us? </label>
  <mat-radio-group aria-labelledby="how-did-you-hear-from-us" class="my-16" formControlName="howDidYouHearFromUs">
    <mat-radio-button class="flex" *ngFor="let option of options" [value]="option">
      {{option}}
    </mat-radio-button>
  </mat-radio-group>
</form>
<div class="flex justify-between">
  <button class="ml-2 btn btn-background" (click)="back.next(true)">
    Back
  </button>
  <ekipa-continue-button [form]="form" (validClick)="nextStep.next(true)"></ekipa-continue-button>
</div>
