<form [formGroup]="form" class="min-h-60 mb-6">
  <mat-form-field class="w-full">
    <mat-label>{{ 'startup.profile.representedBy' | transloco }}</mat-label>
    <input type="text" class="focus:ring-0" matInput [formControlName]="'displayName'" />
    <mat-error *ngIf="isStartup && displayNameCtrl.valid && displayNameCtrl.touched">
      {{ 'form.representantError' | transloco }}
    </mat-error>
    <mat-error *ngIf="!isStartup && displayNameCtrl.valid && displayNameCtrl.touched">
      Please input your full name
    </mat-error>
  </mat-form-field>

  <mat-form-field class="w-full">
    <mat-label>Email *</mat-label>
    <input type="text" class="focus:ring-0" matInput formControlName="email" />
    <mat-error *ngIf="form.get('email').hasError('email') && !form.get('email').hasError('required')">
      {{ 'form.invalidEmail' | transloco }}
    </mat-error>
    <mat-error *ngIf="form.get('email').hasError('required')">
      {{ 'form.emailRequired' | transloco }}
    </mat-error>
  </mat-form-field>

  <!-- password -->
  <mat-form-field class="w-full">
    <mat-label>{{ 'form.password' | transloco }} *</mat-label>
    <input matInput class="focus:ring-0" [type]="isPasswordHidden ? 'password' : 'text'" formControlName="password" />
    <mat-icon [svgIcon]="isPasswordHidden ? 'visibility_off' : 'visibility'" matSuffix
      (click)="isPasswordHidden = !isPasswordHidden"></mat-icon>

    <mat-error *ngIf="password.errors && password.touched">
      {{ 'form.passwordError' | transloco }}
    </mat-error>
  </mat-form-field>


  <mat-checkbox formControlName="termsAccepted">
    <div class="ml-4">
      <span class="">
        {{ 'register.checkbox1' | transloco
        }}<a class="underline" href="https://ekipa.de/agb" target="_blank"><strong>{{ 'register.checkbox2' | transloco
            }}</strong></a>
        {{ 'register.checkbox3' | transloco
        }}<a class="underline" href="https://ekipa.de/datenschutz" target="_blank"><strong>{{ 'register.checkbox4' |
            transloco }}</strong></a>
        {{ 'register.checkbox5' | transloco }}</span>
    </div>
  </mat-checkbox>
</form>
<div class="flex justify-between">
  <button class="ml-2 btn btn-background" (click)="back.next(true)">
    Back
  </button>
  <ekipa-continue-button [form]="form" (validClick)="nextStep.next(true)"></ekipa-continue-button>
</div>