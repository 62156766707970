import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RegistrationStepInputComponent } from '../registration-step.component';

@Component({
  selector: 'ekipa-profile-type-selection',
  templateUrl: './profile-type-selection.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class ProfileTypeSelectionComponent extends RegistrationStepInputComponent {
  constructor() { super() }


}
