import { NgModule } from '@angular/core';
import { ArrowLeftIconComponent } from './arrow-left-icon.component';
import { CloseIconComponent } from './close-icon/close-icon.component';
import { HomeIconComponent } from './home-icon.component';
import { InfoIconComponent } from './info-icon';
import { JoinRequestsIconComponent } from './join-requests-icon.component';
import { JoinTeamsIconComponent } from './join-teams-icon.component';
import { MyTeamIconComponent } from './my-team-icon.component';
import { SettingsIconComponent } from './settings-icon.component';
import { SubmisisonAreaIconComponent } from './submisison-area-icon.component';
import { UploadIconComponent } from './upload-icon.component';
import { WarningIconComponent } from './warning-icon.component';


@NgModule({
  declarations: [
    HomeIconComponent,
    ArrowLeftIconComponent,
    WarningIconComponent,
    MyTeamIconComponent,
    JoinRequestsIconComponent,
    SubmisisonAreaIconComponent,
    SettingsIconComponent,
    JoinTeamsIconComponent,
    CloseIconComponent,
    UploadIconComponent,
    InfoIconComponent
  ],
  exports: [
    HomeIconComponent,
    ArrowLeftIconComponent,
    WarningIconComponent,
    MyTeamIconComponent,
    JoinRequestsIconComponent,
    SubmisisonAreaIconComponent,
    SettingsIconComponent,
    JoinTeamsIconComponent,
    CloseIconComponent,
    UploadIconComponent,
    InfoIconComponent
  ],
})
export class IconsModule {
}
