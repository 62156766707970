import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';


@Component({
  selector: 'ekipa-login-input',
  templateUrl: './login-input.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    RouterModule,
    MatIconModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    RouterModule
  ]
})
export class LoginInputComponent {
  loginForm: UntypedFormGroup;
  public isHidden = true;
  @ViewChild('password', { static: true }) passwordInput: ElementRef;
  @Output() validLoginEvent = new EventEmitter<any>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {
    this.loginForm = this.formBuilder.group({
      email: [
        null,
        Validators.compose([Validators.required, Validators.email])
      ],
      password: [null, Validators.compose([Validators.required, Validators.minLength(7)])]
    });

  }

  get emailCtrl(): UntypedFormControl {
    return <UntypedFormControl>this.loginForm?.get('email');
  }
  get passwordCtrl(): UntypedFormControl {
    return <UntypedFormControl>this.loginForm?.get('password');
  }

  async onSubmit(): Promise<void> {
    const credentials = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    };
    if (this.loginForm.valid) {
      this.validLoginEvent.emit(credentials);
    } else {
      console.log('form not valid');
    }
  }

  public togglePasswordVisibility(): void {
    this.isHidden = !this.isHidden;
    this.renderer.setAttribute(
      this.passwordInput.nativeElement,
      'type',
      this.isHidden ? 'password' : 'text'
    );
  }


}
