import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Auth, IdTokenResult, User, UserCredential, authState, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ALLOW_UNVERIFIED_ACCOUNT_SIGNIN_COOKIE } from '@ekipa/shared';
import { CookieService as NGXCookieService } from 'ngx-cookie';
import { BehaviorSubject, EMPTY, Observable, map } from 'rxjs';
import { API_URL, IS_PRODUCTION } from '../tokens/tokens';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isBrowser: boolean;
  constructor(
    private snackBar: MatSnackBar,
    @Inject(IS_PRODUCTION) private isProduction: string,
    @Inject(PLATFORM_ID) platformId: string,
    private router: Router,
    private ngxCookieService: NGXCookieService,
    private http: HttpClient,
    @Inject(API_URL) private apiURL: string,
    @Optional() private auth: Auth
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser && auth) {
      this.authState$ = authState(this.auth).pipe(
        map((user: User) => {
          if (
            user !== null
            && this._isEmailVerified(user)
          ) {
            this._user = user;
            user.getIdTokenResult().then(
              (idTokenResult: IdTokenResult) => {
                const hasuraClaims = idTokenResult.claims['https://hasura.io/jwt/claims'];
                const isUserAdmin = hasuraClaims && hasuraClaims['x-hasura-default-role'] === 'manager';
                this.isUserAdmin$.next(isUserAdmin)
              }
            ).catch(err => console.log(err));
            return user;
          }
        })
      )
    }
  }



  get user(): User {
    return this._user;
  }

  private _user: User | null;

  socket: WebSocket;


  isUserAdmin$ = new BehaviorSubject<boolean>(false)


  public readonly authState$: Observable<User | null> = EMPTY;



  signIn(email: string, password: string): Promise<UserCredential | void> {
    return signInWithEmailAndPassword(this.auth, email.toLowerCase(), password).then(
      (credential) => {
        if (this._isEmailVerified(credential.user)) {
          return credential;
        } else {
          const snackBarRef = this.snackBar.open(
            "Email not verified. Check your mail and spam for our link to verify your mail.",
            "Resend Link",
            { duration: 10000 }
          )
          snackBarRef.onAction().subscribe(() => {
            sendEmailVerification(credential.user)
          })
          this.signOut();
          throw new Error()
        }
      }
    ).then(
      (credentials: UserCredential) => {
        const challengeId = localStorage.getItem('currentChallengeID');
        localStorage.removeItem('currentChallengeID');
        // if (challengeId) {
        //   this.router.navigateByUrl(`/challenges/${challengeId}`);
        // } else {
        //   this.router.navigateByUrl('/dashboard');
        // }
        return credentials;
      }).catch((error) => {
        switch (error.code) {
          case "auth/invalid-email":
            {
              this.snackBar.open(
                "Email is invalid",
                null,
                {
                  duration: 10000,
                })
              break;
            }
          case "auth/wrong-password":
            {
              this.snackBar.open(
                "Wrong password",
                null,
                {
                  duration: 10000,
                })
              break;
            }
          case "auth/user-not-found":
            {
              this.snackBar.open(
                "Invalid login",
                null,
                {
                  duration: 10000,
                })
              break;
            }
          case "auth/too-many-requests":
            {
              this.snackBar.open(
                "You attemped too many requests, try again later",
                null,
                {
                  duration: 10000,
                })
              break;
            }
        }
      });;
  }

  signOut(): Promise<void> {
    return signOut(this.auth).then(
      () => {
        this._user = null;
        this.isUserAdmin$.next(false)
        this.router.navigate(["/challenges"]);
      }
    );
  }

  updateCustomClaims() {
    return this.http.get<any>(`${this.apiURL}account/update-custom-claims/${this.user.uid}`)
  };

  sendPasswordResetEmail(email: string) {
    sendPasswordResetEmail(this.auth, email);
  }


  private _isEmailVerified(user: User): boolean {
    if (this.isProduction) {
      return user.emailVerified;
    } else {
      const allowUnverifiedSignIn = this.ngxCookieService.get(ALLOW_UNVERIFIED_ACCOUNT_SIGNIN_COOKIE);
      return user.emailVerified || (allowUnverifiedSignIn !== undefined)
    }
  }
}


