import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'ekipa-continue-button',
  templateUrl: './continue-button.component.html',
  standalone: true,
  imports: [
    MatSnackBarModule,
  ]
})
export class ContinueButtonComponent {
  @Input() form: UntypedFormGroup;
  @Input() additionalValidationState: boolean;
  @Output() validClick = new EventEmitter();


  constructor(private snackBar: MatSnackBar) { }

  isFormValid(): boolean {

    if (this.form.value.termsAccepted != false) {
      if (this.additionalValidationState != null) {
        return this.additionalValidationState && this.form.valid;

      } else {
        return this.form.valid;

      }
    } else {
      this.snackBar.open(
        "Please review and accept the terms and conditions before proceed.",
        null,
        {
          duration: 10000,
        });
    }
  }
  onClick() {
    if (this.isFormValid()) {
      this.validClick.next(this.form.value);
    } else {
      this.snackBar.open(
        "Invalid form input.",
        null,
        {
          duration: 3000,
        });
    }
  }

}
