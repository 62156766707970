<form class="mt-6 mb-4" [formGroup]="loginForm" (submit)="onSubmit()" >
  <div class="mx-auto max-w-lg ">
    <mat-form-field class="w-full" >
      <mat-label>Email</mat-label>
      <input
        type="email"
        autocomplete="on"
        matInput
        formControlName="email"
      />
      <mat-error
        *ngIf="emailCtrl.hasError('email') && !emailCtrl.hasError('required') "
      >
        {{ 'form.invalidEmail' | transloco }}
      </mat-error>
      <mat-error *ngIf="emailCtrl.hasError('required')">
        {{ 'form.emailRequired' | transloco }}
      </mat-error>
    </mat-form-field>

    <mat-form-field  class="w-full" autocom>
      <mat-label>{{ 'form.password' | transloco }}</mat-label>
      <input
        matInput
        autocomplete="on"
        [type]="isHidden ? 'password' : 'text'"
        formControlName="password"
        class="focus:ring-0"
        #password type="password"
      />
      <mat-icon [svgIcon]="isHidden ? 'visibility_off' : 'visibility' " matSuffix (click)="isHidden = !isHidden"></mat-icon>

      <mat-error *ngIf="passwordCtrl.hasError('required') ">
        {{ 'form.passwordError' | transloco }}
      </mat-error>
    </mat-form-field>

    <button type="submit"
            class="btn btn-primary mt-4">
      {{ "loginPageBox.login" | transloco }}
    </button>


    <label class="block text-gray-500 font-light my-4">
      <a
      routerLink="/reset-password"
      class="cursor-pointer tracking-tighter text-sm border-b-2 border-gray-200 hover:border-gray-400"
      >
        <span>{{"actions.forgotYourPassword" | transloco}}</span>
      </a>
    </label>
  </div>
</form>


