import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { allFaculties, allSkills, countries, innovatorTypes, universities } from '@ekipa/shared';
import { TranslocoModule } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IPersonalProfile } from '../../../../models/profile.model';
import { ContinueButtonComponent } from '../continue-button/continue-button.component';
import { RegistrationStepInputComponent } from '../registration-step.component';

@Component({
  selector: 'ekipa-individual-expertise',
  templateUrl: './individual-expertise.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatInputModule,
    MatIconModule,
    ContinueButtonComponent,
    TranslocoModule
  ]
})
export class IndividualExpertiseComponent extends RegistrationStepInputComponent implements OnInit {
  innovatorTypes = innovatorTypes;
  universities = universities;

  allSkills = allSkills;
  filteredSkills$: Observable<string[]>;
  skills = [];
  skillInputCtrl = new FormControl('');
  @ViewChild('skillInput') skillInput: ElementRef;

  allFaculties = allFaculties;
  filteredFaculties$: Observable<string[]>;
  faculties = [];
  facultyInputCtrl = new FormControl('');
  @ViewChild('facultyInput') facultyInput: ElementRef;

  countriesList = countries;

  birthYearList = Array.from({ length: 100 }, (_, i) => (new Date().getFullYear() - 18) - i);
  @ViewChild('birthYearInput') birthYearInput: ElementRef;




  profile: IPersonalProfile;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.skills = this.form.get('skills').value;
    this.faculties = this.form.get('faculties').value;

    this.filteredFaculties$ = this.facultyInputCtrl.valueChanges.pipe(
      startWith(null),
      map((input: string | null) =>
        input ? this._filterFaculties(input) : this.allFaculties.slice()
      )
    );

    this.filteredSkills$ = this.skillInputCtrl.valueChanges.pipe(
      startWith(null),
      map((input: string | null) =>
        input ? this._filterSkills(input) : this.allSkills.slice()
      )
    );

  }

  addFaculty(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if ((value || '').trim()) {
      this.faculties.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
    this.form.get('faculties').setValue(this.faculties);
  }

  addFacultyOnBlur(event: FocusEvent) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent = {
        chipInput: {
          inputElement: this.facultyInput.nativeElement
        },
        value: this.facultyInput.nativeElement.value
      };
      this.addFaculty(matChipEvent as MatChipInputEvent);
    }
  }

  removeFaculty(elem: string): void {
    const index = this.faculties.indexOf(elem);
    if (index >= 0) {
      this.faculties.splice(index, 1);
    }
  }

  selectedFaculty(event: MatAutocompleteSelectedEvent) {
    this.faculties.push(event.option.viewValue);
    this.facultyInput.nativeElement.value = '';
    this.facultyInputCtrl.setValue(null);
  }

  private _filterFaculties(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.allFaculties.filter(
      faculty => faculty.toLowerCase().indexOf(filterValue) >= 0
    );
  }



  addSkill(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if ((value || '').trim()) {
      this.skills.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
    this.skillInputCtrl.setValue(null);
    this.form.get('skills').setValue(this.skills);
  }

  addSkillOnBlur(event: FocusEvent) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent = {
        chipInput: {
          inputElement: this.skillInput.nativeElement
        },
        value: this.skillInput.nativeElement.value
      };
      this.addSkill(matChipEvent as MatChipInputEvent);
    }
  }

  removeSkill(elem: string): void {
    const index = this.skills.indexOf(elem);
    if (index >= 0) {
      this.skills.splice(index, 1);
    }
  }

  selectedSkill(event: MatAutocompleteSelectedEvent) {
    this.skills.push(event.option.viewValue);
    this.skillInput.nativeElement.value = '';
    this.skillInputCtrl.setValue(null);
  }

  private _filterSkills(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.allSkills.filter(
      skill => skill.toLowerCase().indexOf(filterValue) >= 0
    );
  }


  setInnovatorType(innovatorType: string) {
    this.innovatorTypeCtrl.setValue(innovatorType);
    if (innovatorType === 'student' || innovatorType === 'researcher') {
      this.universityCtrl.setValidators([Validators.required]);
      this.form.get('faculties').setValidators([Validators.required]);
    } else {

      this.universityCtrl.setValidators([]);
      this.form.get('faculties').setValidators([]);
    }
    this.form.updateValueAndValidity();
  }

  get innovatorTypeCtrl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('innovatorType');
  }
  get universityCtrl(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('university');
  }

}
