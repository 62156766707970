<form [formGroup]="form" class="min-h-60 mb-6">

  <!-- startup name -->
  <mat-form-field class="w-full">
    <mat-label>Company name</mat-label>
    <input type="text" matInput placeholder="What is the name of your startup ?" formControlName="displayName">
    <mat-error *ngIf="form.get('displayName').hasError('required')">
      Startup name is required.
    </mat-error>
  </mat-form-field>

  <!-- industry -->
  <mat-form-field class="w-full" *ngIf="!challengesService.isFast50">
    <mat-label>{{ 'startup.profile.industry' | transloco }}</mat-label>
    <mat-select formControlName="industry" [ngClass]="{ 'is-invalid': !industryCtrl.valid }">
      <mat-option *ngFor="let industry of industriesList" [value]="industry" class="h-6">
        <span>{{ industry }}</span>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="!industryCtrl.valid && industryCtrl.touched">
      {{ 'form.industryError' | transloco }}
    </mat-error>
  </mat-form-field>

  <!-- founding year -->
  <mat-form-field class="w-full">
    <mat-label>Founding year</mat-label>
    <mat-select formControlName="foundedIn">
      <mat-option *ngFor="let year of foundingYears" [value]="year">
        {{"In " + year }}
      </mat-option>
      <mat-option [value]="2015">Prior to 2016</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- country -->
  <mat-form-field class="w-full">
    <mat-label>{{ "startup.profile.country" | transloco }}</mat-label>
    <mat-select formControlName="country" required>
      <mat-option *ngFor="let country of countriesList" [value]="country" class="h-6">
        <span>{{ country }}</span>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('country').hasError('required')"> Company location is required.</mat-error>
  </mat-form-field>
</form>

<div class="flex justify-between">
  <button class="ml-2 btn btn-background" (click)="back.next(true)">
    Back
  </button>
  <ekipa-continue-button [form]="form" (validClick)="nextStep.next(true)">
  </ekipa-continue-button>
</div>
